import { createApp } from 'vue'

const App = {
    delimiters: ['[[', ']]'],
    data() {
      return {
        loginRequired: false,
      }
    },

    methods: {
      handleModalClose() {
        this.loginRequired = false
      },
      handleLoginRequired() {
        this.loginRequired = true
      },
    }
}
createApp(App).mount('#app')
